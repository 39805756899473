a:link { text-decoration: none; color: inherit }
a:visited { text-decoration: none; color: inherit}
a:hover { text-decoration: none;  color: inherit}
a:active { text-decoration: none; color: inherit}

a.show-link {
  text-decoration: underline;
  color: #a17a67ff;
}

.navbar-default {
    background-color: transparent !important;
    border-color: transparent !important;
}

.navbar-header {
  color: black;
  text-align: left;
  padding: 1em 2em;
}

.heading {
  font-size: 1.2em;
  line-height: 2.2em;
}

.float-right {
  float: right;
  position: absolute;
  right: 2em;
}

.btn-connect {
  min-width: 13em;
}

.btn-about {
  right: 16em;
}

@media (max-width: 460px) {
  .btn-connect {
    margin-top: 3em;
  }
}

.jhatu-btn.waiting {
  background-color: #444;
  cursor: wait;
}

.jhatu-btn {
  text-align: center;
  color: white;
  background-color: #000;
  padding: 0.5em 2em;
  border-radius: 0.6em;
  cursor: pointer;
  display: inline-block;
}

.jhatu-btn:hover {
  background-color: #444;
}



.App {
  font-size: 1.2em;
  font-family: 'Comfortaa';
  text-align: center;
  min-height: 100vh;
  background-image: linear-gradient(-160deg, #fae9e1ff 65%, #a17a67ff 35%);
  background-size: 100vw 100vh;
  height: 100%;
  background-repeat: no-repeat;
}

.footer {
  position: absolute;
  bottom: 2em;
  background-color: #fae9e160;
  width: 100%;
  padding: 0.5em 2em;
  font-size: 1.2em;
  font-family: 'Comfortaa';
  text-align: center;
  color: white;
  cursor: pointer;
}

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}
.lds-circle > div {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: #fff;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

