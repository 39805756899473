.insure-success-msg {
	margin-top: 4em;
}

.insure-btn-pay {
	min-width: 11em;
}

.insure-nft-image {
	margin-top:  1em;
	border-radius: 0.5em;
    width: 30em;
    box-shadow: 10px 10px 5px gray;
}