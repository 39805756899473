.about-success-msg {
	margin-top: 4em;
}

.about-nft-image {
	margin-top:  1em;
	border-radius: 0.5em;
    width: 30em;
    box-shadow: 10px 10px 5px gray;
}

.about-newpara {
	margin-left: 20vw;
	margin-right: 20vw;
	margin-top: 2em;
	text-align: -webkit-left;
	text-align: -moz-left;
}

.about-newpara.center {
	text-align: -webkit-center;
	text-align: -moz-center;
}

.about-page {
	max-height: 70vh;
	overflow-y: auto;
	overflow-x: hidden;
	padding-left: 1em;
}